<template>
  <v-container>
    <div v-if="alert">
      <v-alert text color="info">
        <v-row align="center" no-gutters>
          <v-col cols="12" xs="10" sm="10" md="10" lg="10">{{ alertmsg }}</v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn cols="12" xs="1" sm="1" md="1" lg="1" color="info" outlined @click="closealert"> Okay </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <layout v-if="!exitpage" class="green" align="center" justify="center">
      <h3>CHANGE PASSWORD</h3>
      <v-row align="center" justify="center">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <!-- <v-text-field v-model="EmpID" :counter="6" :rules="EmpIDRules" label="Employee ID" required> </v-text-field>
       <v-text-field v-model="email" :rules="emailRules" label="E-mail" required >Email ID</v-text-field>-->

          <div>
            <h4>EmpID</h4>
            <span>{{ EmpID }}</span>
          </div>
          <div>
            <h4>E-mail</h4>
            <span>{{ email }}</span>
          </div>
          <!-- password -->
          <v-text-field
            v-model="password"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            label="New Password"
            hint="At least 8 characters"
            counter
          >
          </v-text-field>
          <v-text-field
            v-model="confirmPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label=" Confirm Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          >
          </v-text-field>
          <!--Password-->
          <v-btn color="success" :disabled="!valid" class="mr-4" @click="changePassword"> Submit Password </v-btn>
        </v-form>
      </v-row>
    </layout>
  </v-container>
</template>

<script>
import { auth, usersRef } from "../fb"
export default {
  name: "changepassword",
  //  props:['roles'],
  data: () => ({
    valid: true,
    show1: false,
    show2: false,
    password: "",
    confirmPassword: "",
    name: "",
    EmpID: "",
    EmpIDRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match",
    },

    checkbox: false,
    lazy: false,
    error: "",
    domain: "",
    alert: false,
    alertmsg: "",
    exitpage: false,
    roles: "",
  }), //end of data
  async created() {
    this.roles = await this.$store.state.userProfile
    this.domain = this.roles.domain
    this.EmpID = this.roles.EmpID
    this.email = this.roles.email
  },

  methods: {
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    async changePassword() {
      var user = auth.currentUser
      var newPassword = this.password
      var authemail = null
      if (this.confirmPassword == this.password) {
        if (user != null) {
          await user.providerData.forEach(function (profile) {
            authemail = profile.email
          })
          console.log("inside changePassword", authemail)
          if (this.email == authemail) {
            await user
              .updatePassword(newPassword)
              .then(function () {
                console.log("inside updated password success", authemail)
                //this.alert=true;
                //this.alertmsg="The password has been changed , please login again"
              }) // end of user.update password try method
              .catch(function (error) {
                console.log("Error in password-change", error)
                this.alert = true
                this.alertmsg = "Error in password-change" + error
                ;(this.password = ""), (this.confirmPassword = "")
              }) // end of user.update password catch
            console.log("before calling changerolestable")
            this.changerolestable(authemail)
          } // end of (this.email==authemail)
          else {
            alert("Please enter the logged in user id ")
          }
        } // end of user!==Null
        else {
          alert(" error while password-change")
          this.alert = true
          this.alertmsg = "Error while password-change"
          ;(this.password = ""), (this.confirmPassword = "")
        }
      } // end of == password
      else {
        //alert("The password doesn't match tryagain");
        this.alert = true
        this.alertmsg = "The password doesn't match try-again"
        this.password = null
        this.confirmPassword = null
        this.valid = false
      }
    }, // end of change password
    async changerolestable(
      authemail // this will update the roles table status as "active" as the in the new user registration the status is "newuser"
    ) {
      var datarecord = []
      console.log("in  changerolestable", this.alert)
      datarecord = await usersRef.where("email", "==", authemail).get()
      if (datarecord.docs.length == 1)
        await usersRef
          .doc(datarecord.docs[0].id)
          .update({ status: "active", modifiedOn: new Date().toISOString().slice(0, 10) }) // end of dbcollection update
      //alert("The password has been changed , please login again");
      this.alert = true
      this.alertmsg = "The password has been changed , please login again"
      this.exitpage = true
      ;(this.password = ""), (this.confirmPassword = "")
      //this.$router.replace({ name: "login" });
    }, // end of changerolestable
    closealert() {
      this.alert = false
      this.alertmsg = ""
      if (this.exitpage) {
        auth.signOut()
        this.$emit("signOut")
        // this.$router.replace({ name: "login" });
        this.$router.push({ name: "login" })
      }
    },
  }, // end of methods
}
</script>
